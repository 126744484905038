var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"contain"},[_vm._m(3),_c('div',{staticClass:"h2"},[_vm._v(" 在校大学老师一对一辅导，为您读研究生和博士期间遇到困难解忧。包含毕业资料整理，题目确定及思路梳理，开题报告分析，论据论点分析，期刊写作及投稿指导，答辩指导，专利申请指导等 ")]),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_c('div',{staticClass:"btn",on:{"click":function($event){_vm.show = !_vm.show}}},[_c('img',{attrs:{"src":require("@/assets/image/icon70.png"),"alt":""}}),_vm._v(" 联系客服 "),_c('div',{staticStyle:{"position":"absolute","left":"380px"}},[(_vm.show)?_c('telToast',{attrs:{"show":_vm.show}}):_vm._e()],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img1"},[_c('div',{staticClass:"h1"},[_vm._v("硕博毕业辅导")]),_c('div',{staticClass:"h2"},[_c('span',[_vm._v("1v1专业辅导")]),_c('span',[_vm._v("为您解忧")])]),_c('div',{staticClass:"img_tip"},[_vm._v("全科覆盖+文章发表支持")]),_c('div',{staticClass:"h3"},[_c('div',{staticClass:"h3_item"},[_c('img',{attrs:{"src":require("@/assets/image/icon102.png"),"alt":""}}),_vm._v("核心期刊 ")]),_c('div',{staticClass:"h3_item"},[_c('img',{attrs:{"src":require("@/assets/image/icon102.png"),"alt":""}}),_vm._v("学术会议 ")]),_c('div',{staticClass:"h3_item"},[_c('img',{attrs:{"src":require("@/assets/image/icon102.png"),"alt":""}}),_vm._v("硕博毕业 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box1"},[_c('div',{staticClass:"box1_left"},[_c('img',{attrs:{"src":require("@/assets/image/icon76.png"),"alt":""}}),_c('div',{staticClass:"box1_left_con"},[_c('div',[_vm._v("大牛导师，全科覆盖")]),_c('div',[_vm._v("与国内外知名高校导师近距离接触，全科覆盖")])])]),_c('div',{staticClass:"box1_right"},[_c('img',{attrs:{"src":require("@/assets/image/icon77.png"),"alt":""}}),_c('div',{staticClass:"box1_left_con"},[_c('div',[_vm._v("全程跟踪，定期反馈")]),_c('div',[_vm._v("1对1全程跟踪咨询，24小时答疑，专属时间规划进度管理")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box1",staticStyle:{"margin-top":"40px","margin-bottom":"20px"}},[_c('div',{staticClass:"box1_left"},[_c('img',{attrs:{"src":require("@/assets/image/icon78.png"),"alt":""}}),_c('div',{staticClass:"box1_left_con"},[_c('div',[_vm._v("精致教学，能力提升")]),_c('div',[_vm._v("深耕硕博辅导教研，拒绝学术不端，全面提升学员专业能力")])])]),_c('div',{staticClass:"box1_right"},[_c('img',{attrs:{"src":require("@/assets/image/icon79.png"),"alt":""}}),_c('div',{staticClass:"box1_left_con"},[_c('div',[_vm._v("产出保障，发表优势")]),_c('div',[_vm._v(" 更高的辅导质量+更成熟的发表渠道+更可靠的学术经验+更合理的辅导费用 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h1"},[_c('div',{staticClass:"h1_top"},[_c('img',{attrs:{"src":require("@/assets/image/icon67.png"),"alt":""}}),_c('div',[_vm._v("硕博辅导介绍")]),_c('img',{attrs:{"src":require("@/assets/image/icon68.png"),"alt":""}})]),_c('img',{staticClass:"h1_bottom",attrs:{"src":require("@/assets/image/icon69.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h1"},[_c('div',{staticClass:"h1_top"},[_c('img',{attrs:{"src":require("@/assets/image/icon67.png"),"alt":""}}),_c('div',[_vm._v("辅导项目介绍")]),_c('img',{attrs:{"src":require("@/assets/image/icon68.png"),"alt":""}})]),_c('img',{staticClass:"h1_bottom",attrs:{"src":require("@/assets/image/icon69.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table"},[_c('div',{staticClass:"table_top"},[_c('div',[_vm._v("项目级别")]),_c('div',[_vm._v("服务项目")]),_c('div',[_vm._v("项目介绍")])]),_c('div',{staticClass:"table_item"},[_c('div',[_vm._v("A项")]),_c('div',[_vm._v("前端服务")]),_c('div',[_vm._v(" 题目选择、思路沟通、开题报告写作指导、数据分析、模型分析、毕业文章意见分析及指出，与硕士导师基本保持同等指导；直至指导老师认可 ")])]),_c('div',{staticClass:"table_item"},[_c('div',[_vm._v("B项")]),_c('div',[_vm._v("前端+中端服务")]),_c('div',[_vm._v(" 在A项基础上，进行具体资料协助提供（数据类型、模型创建、公式），每一章节具体分析指导，直到外审老师审核完毕 ")])]),_c('div',{staticClass:"table_item"},[_c('div',[_vm._v("C项")]),_c('div',[_vm._v("全程服务")]),_c('div',[_vm._v(" 在A+B基础上，持续辅导到答辩台上，全程参与指导，包括从前期题目确定到最终答辩定稿 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h1"},[_c('div',{staticClass:"h1_top"},[_c('img',{attrs:{"src":require("@/assets/image/icon67.png"),"alt":""}}),_c('div',[_vm._v("服务详细介绍")]),_c('img',{attrs:{"src":require("@/assets/image/icon68.png"),"alt":""}})]),_c('img',{staticClass:"h1_bottom",attrs:{"src":require("@/assets/image/icon69.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tip1"},[_c('div',{staticClass:"tip1_title"},[_vm._v("A项前端服务")]),_c('div',{staticClass:"tip_list"},[_c('div',{staticClass:"tip_item1"},[_c('div',{staticClass:"tip_item1_left"},[_c('span',[_vm._v("1.选题和课题选择")]),_c('span',[_vm._v("帮助学生确定合适的研究选题，讨论可能的研究方向和兴趣点，并符合学生背景和专业的课题。")])]),_c('div',{staticClass:"tip_item1_left"},[_c('span',[_vm._v("2.明确研究方向和目标")]),_c('span',[_vm._v("与学生一起明确研究方向和文章的整体目标，确保研究内容和重点的合理性和可行性。")])])]),_c('div',{staticClass:"tip_item1"},[_c('div',{staticClass:"tip_item1_left"},[_c('span',[_vm._v("3.开题报告")]),_c('span',[_vm._v("指导学生撰写开题报告包括问题陈述，研究目标，研究方法和计划等内容，确保开题报告符合学校要求。")])]),_c('div',{staticClass:"tip_item1_left"},[_c('span',[_vm._v("4.文献综述和查找")]),_c('span',[_vm._v("引导学生进行文献综述帮助学生找到相关的学术文献和资料，并指导学生对文献进行分析和总结。")])])]),_c('div',{staticClass:"tip_item1"},[_c('div',{staticClass:"tip_item1_left"},[_c('span',[_vm._v("5.确定文章结构")]),_c('span',[_vm._v("构建文章的逻辑框架，确定文章章节主题，并于整体研究目标紧密相关。")])]),_c('div',{staticClass:"tip_item1_left"},[_c('span',[_vm._v("6.疑难解答指导")]),_c('span',[_vm._v("帮助学生澄清问题的本质，根据学生的问题，提供相应的解决方案和建议，直到指导老师通过。")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tip2"},[_c('div',{staticClass:"tip2_title"},[_vm._v("B项前端+中端服务")]),_c('div',{staticClass:"tip_list"},[_c('div',{staticClass:"tip_item"},[_c('span',[_vm._v("1.A项服务中所有项目")]),_c('span',[_vm._v("在A项服务基础上更进一步提供具体和深入的指导，确保研究的顺利进行。")])]),_c('div',{staticClass:"tip_item"},[_c('span',[_vm._v("2.章节分析指导")]),_c('span',[_vm._v("对文章每一章节的写作进行具体分析和指导，确保文章的逻辑结构和内容合理。")])]),_c('div',{staticClass:"tip_item"},[_c('span',[_vm._v("3.外审老师审核完毕")]),_c('span',[_vm._v("提供持续的指导，直到外审老师审核完毕，确保文章的质量和进度。")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tip3"},[_c('div',{staticClass:"tip3_title"},[_vm._v("C项全程服务")]),_c('div',{staticClass:"tip_list"},[_c('div',{staticClass:"tip_item1"},[_c('span',[_vm._v("1.B项服务中所有项目")]),_c('span',[_vm._v("在B项服务基础上进一步提供具体和深入的指导，确保研究的高效优质完成。")])]),_c('div',{staticClass:"tip_item2"},[_c('div',{staticClass:"tip_item2_left"},[_c('span',[_vm._v("2.全程指导")]),_c('span',[_vm._v("针对学生从选题到答辩全过程提供连续，全面的指导和支持，确保学生在整个研究过程中得到专业的帮助，顺利完成文章。")])]),_c('div',{staticClass:"tip_item2_left"},[_c('span',[_vm._v("3.修改润色")]),_c('span',[_vm._v("指导修改和润色，直到文章最终定稿，确保文章质量达到要求。")])])]),_c('div',{staticClass:"tip_item1"},[_c('span',[_vm._v("4.答辩指导")]),_c('span',[_vm._v("为学生提供答辩的准备指导，包括演讲技巧和答辩中可能遇到的问题。")])])])])
}]

export { render, staticRenderFns }